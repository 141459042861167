/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, Suspense, useState, useEffect } from 'react';
import {
    Route,
    createRoutesFromElements,
    createBrowserRouter,
    RouterProvider,
    Navigate
} from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import addNotification from 'react-push-notification';
import { URLS } from './constants/routes';
import { sendMsg } from "./assets/images";
import {
    getTwilioChatToken
} from "./services/chatServices";
import { GetUserDetails } from "./reduxData";
import Layout from './layout';
const NotFound = React.lazy(() => import("./privateScreens/NotFound"));
const NotFoundLogout = React.lazy(() => import("./publicScreens/NotFoundLogout/NotFoundLogout"))
const CMS = lazy(() => import("./privateScreens/CMS"));
const Dashboard = lazy(() => import("./privateScreens/Dashboard"));
const TTATeam = lazy(() => import("./privateScreens/TTATeam"));
const DeletedChats = lazy(() => import("./privateScreens/DeletedChats"));
const ModerateChats = lazy(() => import("./privateScreens/ModerateChats"));
const Chat = lazy(() => import("./privateScreens/Chat"));
const Login = lazy(() => import("./publicScreens/Authentication/Login"));
const NotificationTemplates = lazy(() => import("./privateScreens/NotificationTemplates"));
const MasterTemplates = lazy(() => import("./privateScreens/MasterTemplates"));
const Notifications = lazy(() => import("./privateScreens/NotificationDetails"));
const SubscriptionGroups = lazy(() => import("./privateScreens/SubscriptionGroups"));
const EmailTemplate = lazy(() => import("./privateScreens/EmailTemplate"))

const AppRouter = () => {
    const Conversations = require("@twilio/conversations");

    const search = window.location.search;
    const searchParams = new URLSearchParams(search);
    const redirect = searchParams.get("redirect");

    const isLogin = useIsAuthenticated();

    const emailGet = GetUserDetails().chatEmail;
    const [twilioChatToken, setTwilioChatToken] = useState(null);
    const [userClient, setUserClient] = useState(null);

    useEffect(() => {
        if (isLogin && redirect) {
            window.location.href = `${window.location.origin}/${redirect}`;
        }
    }, [redirect, isLogin])

    function isLocationInMsgLocations() {
        const msgLocations = [
            "/chats/jobs"
        ];
        const currentPathname = window.location.pathname;

        return msgLocations.some(path => {
            const pattern = new RegExp('^' + path.replace(/\/<dynamic>/g, '/[\\w\\d-]+') + '$');
            return pattern.test(currentPathname);
        });
    }

    const [titleChange, setTitleChange] = useState(false)

    const changeTitle = (msg) => {
        document.title = msg
    }

    const handleVisibilityChange = () => {
        if (document.hidden) {
            // Tab is not visible (user switched to another tab or minimized the browser)
            // console.log('Tab is not visible');
        } else {
            // Tab is visible (user switched back to the tab)
            // console.log('Tab is visible');
            setTitleChange(false)
        }
    };

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Cleanup the event listener when the component unmounts
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    useEffect(() => {
        let intervalId
        if (titleChange) {
            intervalId = setInterval(() => {
                // Update the title based on your logic
                changeTitle(document.title === `New Message` ? `TTA Connect` : "New Message");
            }, 1000); // Update every 1000 milliseconds (1 second)
        } else {
            setTitleChange(false);
            clearInterval(intervalId);
            changeTitle("TTA Connect");
        }

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [titleChange])

    const sendNewMessageNotification = (e) => {
        if (e && !isLocationInMsgLocations() && e.state.attributes?.main) {
            setTitleChange(true)
            addNotification({
                title: e.state.attributes?.groupName ? `${e.state.attributes?.name} - ${e.state.attributes?.groupName}` : `${e.state.attributes?.name}`,
                message: e.state.attributes?.type === "media" ? `${e.state.attributes?.fileArray.length} file(s) sent` : e.state.body,
                duration: 5000,
                icon: sendMsg,
                onClick: () => {
                    if (e?.state?.attributes?.groupName) {
                        window.open(`${window.location.origin}/chats/jobs?projectId=${e.conversation?._internalState?.attributes?.projectId}&groupId=${e.state.attributes?.groupId}`, "_blank")
                    } else {
                        window.open(`${window.location.origin}/chats/jobs?projectId=${e.conversation?._internalState?.attributes?.projectId}&email=${e.state?.author}`, "_blank")
                    }
                },
                native: true
            })
        }
    }

    useEffect(() => {
        if (userClient) {
            userClient?.on("messageAdded", e => {
                sendNewMessageNotification(e)
            })
        }
        return () => {
            if (!isLogin) {
                userClient?.removeAllListeners();
                userClient?.shutdown()
            }
        }
    }, [isLogin, userClient])

    useEffect(() => {
        if (emailGet) {
            getLoggedInUsersChatToken()
        }
    }, [emailGet, isLogin])

    useEffect(() => {
        if (twilioChatToken) {
            createLoggedInUserClient()
        }
    }, [twilioChatToken])

    useEffect(() => {
        if (userClient) {
            twilioListerners(userClient)
        }
    }, [userClient])

    const getLoggedInUsersChatToken = () => {
        getTwilioChatToken({ email: emailGet })
            .then((res) => {
                setTwilioChatToken(res.data)
            }).catch(e => {
                console.log("Eee error", e)
            })
    }

    const createLoggedInUserClient = async () => {
        const userClientIns = new Conversations.Client(twilioChatToken)
        userClientIns.on("connectionStateChanged", async (e) => {
            if (e === "connected") {
                console.log(e)
                setUserClient(userClientIns)
            } else if (e === "denied") {
                console.log("Denied", userClientIns)
            } else {
                console.log(e)
            }
        }
        )
    }

    const twilioListerners = client => {
        client.on("tokenAboutToExpire", async () => {
            console.log("Token about to expire");
            const fetchTokenResponse = await getTwilioChatToken({ email: emailGet })
            const newToken = fetchTokenResponse?.data;
            client.updateToken(newToken);
        });

        client.on("tokenExpired", async () => {
            console.log("Token has expired");
            const fetchTokenResponse = await getTwilioChatToken({ email: emailGet })
            const newToken = fetchTokenResponse?.data;
            client.updateToken(newToken);
        });
    }

    let routes;
    if (isLogin) {
        routes = createBrowserRouter(
            createRoutesFromElements(
                <Route element={<Layout userClient={userClient} />}>
                    <Route path={`${URLS.CHATS}/:tab`} element={<Chat userClient={userClient} Conversations={Conversations} />} />
                    <Route path={URLS.DASHBOARD} element={<Dashboard />} />
                    <Route path={URLS.TTA_TEAM} element={<TTATeam />} />
                    <Route path={`/cms/:activeTab`} element={<CMS />} />
                    <Route path={`/cms/:activeTab/:dashActiveTab`} element={<CMS />} />
                    <Route path={URLS.DELETED_CHATS} element={<DeletedChats userClient={userClient} Conversations={Conversations} />} />
                    <Route path={URLS.MODERATE_CHAT} element={<ModerateChats />} />
                    <Route path={URLS.NOTIFICATION_TEMPLATE} element={<NotificationTemplates />} />
                    <Route path={URLS.MASTER_TEMPLATE} element={<MasterTemplates />} />
                    <Route path={URLS.NOTIFICATIONS + "/:id"} element={<Notifications />} />
                    <Route path={URLS.SUBSCRIPTION_GROUPS} element={<SubscriptionGroups />} />
                    <Route path={URLS.EMAIL_TEMPLATE} element={<EmailTemplate />} />
                    <Route path={URLS.EMAIL_TEMPLATE + "/:id"} element={<EmailTemplate />} />
                    <Route path="/" element={<Navigate to={"/chats/jobs"} />} />
                    <Route path="*" element={<NotFound />} exact={true} />
                </Route>
            )
        )
    } else {
        routes = createBrowserRouter(
            createRoutesFromElements(
                <Route element={<Layout userClient={userClient} />}>
                    <Route path={URLS.LOGIN} element={<Login />} />
                    <Route path="*" element={<NotFoundLogout />} exact={true} />
                </Route>
            )
        )
    }

    return (
        <Suspense fallback={"Loading"}>
            <RouterProvider router={routes} />
        </Suspense>
    )
}

export default AppRouter