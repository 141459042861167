import { URLS } from "./routes"

export const MenuLinks = [
    {
        name: "Messages",
        path: `${URLS.CHATS}/jobs`,
        subMenu: false,
    },
    {
        name: "Dashboard",
        path: URLS.DASHBOARD,
        subMenu: false,
    },
    {
        name: "TTA Team",
        path: URLS.TTA_TEAM,
        subMenu: false,
    },
    {
        name: "Content Management",
        path: URLS.CONTENT_MANAGEMENT,
        subMenu: [
            {
                name: "Talent Content",
                path: URLS.CONTENT_MANAGEMENT,
                active: URLS.CONTENT_MANAGEMENT,
            },
            {
                name: "Client Content",
                path: URLS.CONTENT_MANAGEMENT,
                active: "/cms/1/2",
            },
            {
                name: "Notification Templates",
                path: URLS.NOTIFICATION_TEMPLATE,
                active: URLS.NOTIFICATION_TEMPLATE,
            },
        ]
    },
]