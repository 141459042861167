import { useEffect } from "react";

const useClick = (callback,ref) => {
useEffect(() => {
    document.addEventListener("click", callback, true);
    return () => {
      document.removeEventListener("click", callback, true);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
}

export const useGlobalClick = (callback,ref) => {
    return useClick( callback,ref);
};