import { Outlet } from 'react-router-dom';
import Navbar from '../components/navbar';
import Footer from '../components/footer/Footer';

const Layout = ({ userClient }) => {
    return (
        <>
            <Navbar userClient={userClient} />
            <Outlet />
            <Footer />
        </>
    );
};

export default Layout;
