import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation, Link } from 'react-router-dom';
import classnames from "classnames";
import {
  logo,
  whiteArrowDown
} from "../../assets/images";
import { removeUserDetails } from "../../dataSlice";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { MenuLinks } from "../../constants/MenuLinks";
import { URLS } from "../../constants/routes";
import { deleteAuthTokens } from "../../utils/localStorage";
import { useGlobalClick } from "../../utils/clickListener";
import Button from "../button";
import "./Navbar.css";

export function useOnClickOutside(ref, handler) {

  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

let Navbar = ({ userClient }) => {
  const location = useLocation();
  const dispatch = useDispatch()
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated()
  const logoutRequest = {
    account: accounts[0],
    mainWindowRedirectUri: process.env.REACT_APP_FE_URL,
  };

  const logout = () => {
    deleteAuthTokens()
    dispatch(removeUserDetails())
    instance.logoutPopup(logoutRequest)
  }

  const [openNavbar, setOpenNavbar] = useState(false);
  const openNavbarRef = useRef(null);

  const handleClick = (event) => {
    if (openNavbarRef?.current && !openNavbarRef?.current?.contains(event.target)) {
      console.log("in hererrere")
      setOpenNavbar(false);
    }
    return
  };

  useGlobalClick(handleClick, openNavbarRef);

  return (
    <div className="container-fluid newNavbarContainer d-flex align-items-center justify-content-between">
      <div className="d-flex ">
        <Link to={URLS.DASHBOARD}>
          <img className="NavbarBrandLogo" src={logo} alt="logo" />
        </Link>
        {
          isAuthenticated &&
          <div className="d-flex align-items-center">
            {
              MenuLinks.map((links, idx) => (
                <React.Fragment key={idx}>
                  <Link
                    // to={links.external ? ""  : links?.path}
                    to={links.external ? "" : links?.subMenu ? window.location.pathname : links?.path}
                    key={links.name}
                    className={classnames(
                      links.external ? "NewNavBarLinks" :
                        location.pathname === links?.path ||
                          location.pathname?.split("/")[1] === links?.path?.split("/")[1]
                          ? "NewNavBarLinks NewNavBarLinksActive"
                          : "NewNavBarLinks"
                    )
                    }
                    onClick={(e) => {
                      if (links.subMenu) {
                        setOpenNavbar(!openNavbar)
                      }
                    }
                    }
                  >
                    {links.name}
                    <span className="position-relative">
                      {
                        links?.subMenu ?
                          openNavbar ?
                            <img src={whiteArrowDown} alt="upArrow" className="upArrow" />
                            :
                            <img src={whiteArrowDown} alt="downArrow" className="downArrow" />
                          : ""
                      }
                    </span>
                    {
                      (links?.subMenu && openNavbar) ?
                        <div className="subMenuBar">
                          {
                            links.subMenu.map((subLinks, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <Link to={subLinks.path} className={classnames("subMenuLinks", location.pathname === subLinks?.active ? "subMenuActiveLink" : "")}>
                                    {subLinks.name}
                                  </Link>
                                  <br />
                                </React.Fragment>
                              )
                            })
                          }
                        </div>
                        : ""
                    }
                  </Link>
                </React.Fragment>
              ))
            }
          </div>
        }
      </div>
      {
        isAuthenticated &&
        <div>
          <Button
            label={"Logout"}
            type="submit"
            className="btn-blue "
            onClick={() => {
              logout();
              userClient?.shutdown();
            }}
          />
        </div>
      }
    </div>

  );
};
export default Navbar;
