
const ACCESS_TOKEN = "token";
const REFRESH_TOKEN = "refreshToken";

export const saveAuthTokens = (accessToken, refreshToken) => {
  try {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  } catch (err) {}
};

export const deleteAuthTokens = () => {
  try {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
  } catch (err) {
    console.log(err);
  }
};

export const getToken = () => {
  try {
    let token = localStorage.getItem(ACCESS_TOKEN);
    return token
  } catch (err) {
    console.log(err);
  }
};

export const getRefreshToken = () => {
  try {
    let token = localStorage.getItem(REFRESH_TOKEN);
    return token;
  } catch (err) {
    console.log(err);
  }
};