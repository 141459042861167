export const URLS = {
    LOGIN: "/",
    CHATS: "/chats",
    DASHBOARD: "/dashboard",
    QUEUE: "/chats/queue",
    JOBS: "/chats/jobs",
    MY_CHATS: "/chats/mychats",
    TTA_TEAM: "/tta-team",
    CONTENT_MANAGEMENT: "/cms/1/1",
    DELETED_CHATS: "/deleted-chats",
    MODERATE_CHAT: "/browse-chat",
    NOTIFICATION_TEMPLATE: "/cms/notificationTemplate",
    MASTER_TEMPLATE: "/cms/master-template",
    NOTIFICATIONS: "/notifications",
    SUBSCRIPTION_GROUPS: "/subscriptionGroups",
    EMAIL_TEMPLATE: "/emailTemplate",
}