import { LogLevel } from "@azure/msal-browser";

export const msalConfiguration = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: process.env.REACT_APP_MSAL_AUTHORITY,
        redirectUri: process.env.REACT_APP_FE_URL,
        postLogoutRedirectUri: process.env.REACT_APP_FE_URL,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.log(message);
                        return;
                    case LogLevel.Info:
                        // console.log(message);
                        return;
                    case LogLevel.Verbose:
                        // console.log(message);
                        return;
                    case LogLevel.Warning:
                        console.log(message);
                        return;
                    default:
                        return;
                }
            },
            piiLoggingEnabled: false
        },
        windowHashTimeout: 60000,
        iframeHashTimeout: 6000,
        loadFrameTimeout: 0,
        asyncPopups: false
    },
    cache: {
        cacheLocation: "localStorage",
    }
};