import React, { useState } from "react";
import classnames from "classnames";
import "./index.scss";

const Button = ({ className, onClick, label, disabled, style, type, id, name, title }) => {
  const [onClickClassName, setOnClickClassName] = useState("")

  const addClickClassName = (e) => {
    if (className.includes("btn-light")) {
      setOnClickClassName("btn-light-clicked")
    }
    if (className.includes("btn-blue")) {
      setOnClickClassName("btn-blue-clicked")
    }
    if (onClick) {
      onClick(e)
    }
  }
  return (
    <button
      className={classnames("btn main_btn", className, onClickClassName)}
      style={{
        ...style,
      }}
      disabled={disabled}
      onClick={
        (e) => {
          addClickClassName(e)
        }
      }
      type={type}
      id={id ? id : ""}
      name={name}
      title={title}
    >
      {label}
    </button>
  );
};

export default Button;
